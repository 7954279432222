.App{
  background-color: #282828;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
}
.navbar{
  
  background-color: #d66733;
}
/*footer*/
.footer{
  background-color: #282828;
  color: #727272;
}
.a{
  text-decoration: none !important;
  color: #727272 !important;
  font-size: 1rem;
  border: 0;
}
.footer h2{
  color: white;
  font-size: 20px;
  margin-top: 30px;
  font-weight: bold;
  padding-bottom: 20px;
}
.td2{
  color: #727272 !important;
  background-color: #282828 !important;
  border-bottom: #727272 1px solid ;
  padding-top: 0 !important;
} 
.td1{
  color: #d66733 !important;
  background-color: #282828 !important;
  border-bottom: #727272 1px solid ;
  padding-top: 0 !important;
} 
.f-icon{
  color: #727272;
  font-size: 1.8rem;
  padding-bottom: 10px;
}
.li1{
  color: white;
  font-size: .8rem;
  padding-bottom: 10px;
}
.li-b{
  border: 0;
  border-bottom: #727272 1px solid !important;
}
/*Info*/
.location-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #d66733; 
}
.infoback{
  margin-top: 80px;
}
.card {
  height: 100%;
  border: none; 
  border-radius: 0 !important;
  transition: background-color 0.2s ease;
  
}

.card:hover {
  background-color:  rgba(255, 255, 255, 0.8);
}

.infoY{
  transform: translateY(-35px);
}
@media screen and (max-width: 787px) {
  .infoY{
    transform: translateY(0px);
  }
  .card:hover {
    background-color: white;
  }
  .infoback{
    margin-top: 0px;
  }
  .nav-link{
    font-size: 13px !important;
  }
  
}
@media (max-width: 400px){
  .nav-link{
    font-size: 11px !important;
  }
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
}
.card-img-top {
  height: 300px;
  object-fit: cover;
}
.uslugeback{
  background-color: #fff;
}
.card-usl{
 margin: 10px;
 padding: 10px;
}
.lista{
  color: darkslategrey;
}

.card-img-top-container {
  position: relative;
  overflow: hidden;
}

.card-img-top-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(214, 103, 51, 0.7); 
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card-img-top-container:hover::after {
  opacity: 0.5;
}
.tim{
  font-size: 20px;
  border-bottom: 1px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  border-color: #d66733;
}
.tim1{
  font-size: 20px;
  border-bottom: 1px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  border-color: #d66733;
  color: white;
}
/*Image*/
.image-container {
  position: relative;
  overflow: hidden;
  perspective: 1000px;
  border-radius: 10px;
}
.img-fluid1{
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
}
.image-container img {
  transition: transform 0.3s ease;
}

.image-container:hover img {
  transform: scale(1.05);
}

.image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(214, 103, 51, 0.3); */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover::after {
  opacity: 1;
}
/**/
.client_img {
  width: 100%;               /* Zauzima celokupnu širinu kolone */
  height: 150px;            /* Fiksna visina (možeš promeniti prema potrebama) */
  overflow: hidden;         /* Skriva deo slike koji izlazi van okvira */
}

.client_img img {
  width: 100%;              /* Širina slike 100% od kontejnera */
  height: auto;             /* Visina slike 100% od kontejnera */
  object-fit: cover;        /* Prilagođava sliku da ispuni prostor, a da zadrži proporcije */
  filter: grayscale(100%);  /* Crno-bela slika */
  transition: filter 0.3s ease, transform 0.3s ease;
}

.client_img:hover img {
  filter: grayscale(0%);    /* Vraća boje na hover */
  transform: scale(1.05);   /* Blago uvećanje slike na hover */
}

.zoom_img_effect {
  transition: transform 0.3s ease;
}

.client_hover_1 {
  padding-top: 100px;
  padding-bottom: 70px;
}


/* Osnovno stilizovanje za slike */
.carousel-inner img {
  width: 100%; /* Prilagođava širinu slike */
  height: 600px; /* Možete promeniti visinu slike po potrebi */
  object-fit: cover; /* Održava proporcije slika */
  opacity: .4;
}

/* Centriranje teksta u carousel-caption */
.carousel-caption {
  position: absolute;
  top: 40%;  /* Podesite vertikalno u centar */
  left: 15%; /* Podesite horizontalno u centar */
  text-align: center; /* Osigurava da je tekst centriran */
  color: white; /* Postavljanje boje teksta */
}

.carousel-caption h5 {
  font-size: 2rem;
  font-weight: bold;
  color: #d66733;
}

.carousel-caption p {
  font-size: 1.2rem;
  color: #d66733;
}

/* Responzivnost za mobilne uređaje */
@media (max-width: 768px) {
  .carousel-caption h5 {
    font-size: 1.5rem;
  }

  .carousel-caption p {
    font-size: 1rem;
  }
  .carousel-caption  {
    position: absolute;
    top: 10%;  /* Podesite vertikalno u centar */
    left: 15%; /* Podesite horizontalno u centar */
    text-align: center; /* Osigurava da je tekst centriran */
    color: white; /* Postavljanje boje teksta */
  }
  /* Prilagodba visine slika na manjim ekranima */
  .carousel-inner img {
    height: 300px; /* Smanjenje visine slika na manjim ekranima */
  }
}


.ftxt{
  color: #727272;
  text-decoration: none;
}
.privacy-policy {
  padding: 20px;
  background-color: white;
  color: #333;
  font-family: 'Cinzel';
  line-height: 1.6;
  margin-top: 80px;
}

.privacy-policy h1,
.privacy-policy h2 {
  color: #444;
}

.privacy-policy h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.privacy-policy h2 {
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.privacy-policy p {
  margin-bottom: 10px;
}

.privacy-policy ul {
  list-style-type: disc;
  margin-left: 20px;
}

.privacy-policy ul ul {
  list-style-type: circle;
}

.privacy-policy ul li {
  margin-bottom: 5px;
}
/* Kolacici */

.cookies-policy {
  padding: 20px;
  background-color: white;
  font-family: Arial, sans-serif;
}

.cookies-policy h1, .cookies-policy h2, .cookies-policy h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.cookies-policy p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.table-responsive {
  margin-top: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
}

@keyframes vibrate {
  0% { transform: translateX(0); }
  25% { transform: translateX(-2px); }
  50% { transform: translateX(2px); }
  75% { transform: translateX(-2px); }
  100% { transform: translateX(0); }
}

.icon:hover {
  animation: vibrate .4s ease-in-out ;
}

.bold-text {
  font-weight: 900;
}